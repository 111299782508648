import React from 'react';
import CategoriesMovies from "../components/CategoriesMovies";

import PageHeader from '../components/page-header/PageHeader';




const Categories = (props) => {





    const id = props.location.state?.id;
    return (

        <div className="categories">
            <PageHeader>

                Recherche

            </PageHeader>

            <div className="section_filter" >



                <div className="container">
                    <div className="section mb-3">

                        <CategoriesMovies idGenre={id} />

                    </div>
                </div>
            </div>
        </div>



    );
};

export default Categories;