import React from 'react';
import PageHeader from '../components/page-header/PageHeader';
import './about.scss';
import yuba from '../assets/yuba.jpg';
import max from '../assets/max.jpg';
import mat from '../assets/mat.jpg';
import { useParams } from 'react-router';

import { category as cate } from '../api/tmdbApi';

const about = () => {



    return (
        <div>
            <PageHeader>

                A propos

            </PageHeader>



            <div class="container1">
                <div className="box2">
                    <p className="box1text">Qui sommes nous?</p>
                    <p className="box2text">Nous sommes présentement 3 finissants au programme de développement Web du collège O'Sullivan de Québec.
                        Comme travail final, nous avions à faire la conception du visuel et de l'identité du site web par l'utilisation d'une maquette et
                        de wireframe. Ensuite, nous avions à programmer l'ensemble de l'interface pour rendre accessible une liste de films ainsi que les
                        informations reliées aux acteurs. Également, une section recherche était nécéssaire pour permettre une navigation agréable et
                        intuitive pour l'utilisateur X. Le tout se concrétise par l'intégration finale du code et le peaufinage de tout l'ensemble du site web.
                        Construit en React, il permet l'accessibilité rapidement à une liste de films liées à TMBD.  </p>
                </div>
            </div>
            <div className="container2">
                <div className="box3">
                    <p className="box3text">Nos objectifs</p>
                </div>
            </div>
            <div className="container3">
                <p className="objectifnumber">1</p>
                <p className="objectiftext">Notre premier objectif est de réussir à créer une interface lisible et simple d'utilisation.
                </p>
                <br></br>
            </div>
            <div className="container3">
                <p className="objectifnumber">2</p>
                <p className="objectiftext">Notre second objectif consiste à offrir un accès simple à une base de données illimitée et en pleine croissance.</p>
                <br></br>
            </div>
            <div className="container3">
                <p className="objectifnumber">3</p>
                <p className="objectiftext">Établir une méthodologie de travail entre 3 rôles disctincts qui incluent: la programmation, le design et l'intégration.
                    Ceux-ci vont permettre l'harmonisation des compétences pour offrir un site web rapide, responsive et insuitif. </p>
                <br></br>
            </div>
            <div className="container3">
                <p className="objectifnumber">4</p>
                <p className="objectiftext">Livrer un site web aux allures professionnelles, mais tout en apprenant une technologie
                    qui, jusqu'à ce jour, nous était inconnue.  </p>
                <br></br>
            </div>
            <div className="equipe">
                Notre équipe
            </div>

            <div className="equipeframe">

                <div className="equipephoto">
                    <div className="imageequipe">

                    </div>


                </div>

                <div className="equipephoto">

                </div>

                <div className="equipephoto">
                    <div className="photo1">
                        <img src={yuba} alt="" className='img_nous' />
                        <p className="noms">Yuba</p> <hr></hr><br></br>
                        <p className="roles">Programmeur</p>
                    </div>
                    <div className="photo1">
                        <img src={max} alt="" className='img_nous' />
                        <p className="noms">Maxime</p> <hr></hr><br></br>
                        <p className="roles">Intégrateur</p>

                    </div>
                    <div className="photo1">
                        <img src={mat} alt="" className='img_nous' />
                        <p className="noms">Mathieu</p> <hr></hr><br></br>
                        <p className="roles">Designer</p>

                    </div>

                </div>


            </div></div>
    );
};

export default about;