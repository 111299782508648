import React, { useState, useEffect } from "react";
import axios from "axios";

import { useHistory } from 'react-router-dom';

import './categoriemovie.scss';

import MovieCard2 from "./movie-card-2/MovieCard2";
const CategoriesMovies = (props) => {

    const { idGenre } = props;
    const history = useHistory();
    const [data, setData] = useState([]);
    const [id, setId] = useState();
    const [categories, setCategories] = useState([]);

    const idParam = idGenre ? idGenre : id;

    const URL = `https://api.themoviedb.org/3/discover/movie?api_key=ab81b730b71d8188cdd162707e3ce5e1&language=en-US&page=1&with_genres=${idParam}`;
    const URL_GENRES = "https://api.themoviedb.org/3/genre/movie/list?api_key=ab81b730b71d8188cdd162707e3ce5e1&language=en-US";

    useEffect(() => {
        axios.get(URL).then((res) => {
            setData(res.data.results);
        });
        axios.get(URL_GENRES).then((res) => {
            setCategories(res.data.genres);
        });

    }, [idParam]);

    const onChange = (e) => {
        setId(e.target.value);
    }




    return (
        <div className="categoriesMovies">
            {!idGenre && (
                <div className="sort-container">
                    <select value={id} onChange={onChange}>
                        <option>Sélectionnez une catégorie</option>
                        {categories.map(genre => {
                            return <option value={genre.id}>{genre.name}</option>
                        })}
                    </select>
                </div>)}

            {idParam ? (
                <ul className="categoriesMovies-list">
                    {data.map((item) => (

                        <MovieCard2 item={item} key={item.id} onClickMovie={() => history.push(`/movie/${item.id}`,)} />


                    ))}
                </ul>
            ) : (
                <div className="placeholder-container">
                    <h4>Rien à afficher, veuillez sélectionner une catégorie</h4>
                </div>
            )}
        </div>
    )
}

export default CategoriesMovies;