import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router';

import tmdbApi from '../api/tmdbApi';
import apiConfig from '../api/apiConfig';
import { useHistory } from "react-router-dom";

const CastList = props => {

    const { onClickActor } = props;

    const { category } = useParams();

    const [casts, setCasts] = useState([]);

    const history = useHistory();


    useEffect(() => {
        const getCredits = async () => {
            const res = await tmdbApi.credits(category, props.id);
            setCasts(res.cast.slice(0, 5));
        }
        getCredits();
    }, [category, props.id]);
    return (
        <div className="casts">
            {
                casts.map((item, i) => (
                    <a href="">
                        <div key={i} className="casts__item" onClick={() => history.push("/acteur", { id: item.id })}>

                            <div className="casts__item__img" style={{ backgroundImage: `url(${apiConfig.w500Image(item.profile_path)})` }}></div>


                            <p className="casts__item__name">{item.name}</p>

                        </div>
                    </a>
                ))
            }
        </div>
    );
}

export default CastList;
