import React from 'react';

import './footer.scss';

import { Link } from 'react-router-dom';


import logo from '../../assets/tmovie.png';

const Footer = () => {



    return (
        <div className="footer">
            <div className="footer__content container">
                <div className="footer__content__logo">
                    <div className="logo">
                        <img src={logo} alt="" />
                        <Link to="/">Cineclip</Link>
                    </div>
                </div>
                <div className="footer__content__menus">
                    <div className="footer__content__menu">
                        <Link to="/">Accueil</Link>
                        <Link to="/">Notre histoire</Link>
                      
                    </div>
                    <div className="footer__content__menu">
                        <Link to="/">Nous contacter</Link>
                        <Link to="/">FAQ</Link>
                       
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default Footer;
