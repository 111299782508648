import React from 'react';

import './movie-card2.scss';

import { Link } from 'react-router-dom';

import Button from '../button/Button';


import apiConfig from '../../api/apiConfig';

const MovieCard2 = props => {

    const { onClickMovie } = props;

    const item = props.item;



    const bg = apiConfig.w500Image(item.poster_path || item.backdrop_path);

    return (
        <Link onClick={onClickMovie}>
            <div className="movie-card" style={{ backgroundImage: `url(${bg})` }}>
                <Button>
                    <i className="bx bx-play"></i>
                </Button>
            </div>
            <h3>{item.title || item.name}</h3>
        </Link>
    );
}

export default MovieCard2;
