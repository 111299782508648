import React from "react";
import Header from "../components/header/Header";

import { useParams } from 'react-router';

import PageHeader from '../components/page-header/PageHeader';

import { category as cate } from '../api/tmdbApi';

import MovieGrid from "../components/movie-grid/MovieGrid";

const Categories = () => {

    const { category } = useParams();

    console.log(category)

    return (
        <div className="categories">
            <PageHeader>

                {category === cate.movie ? 'Movies' : 'TV Series'}

            </PageHeader>

            <div className="container">
                <div className="section mb-3">

                    <MovieGrid category={category} />

                </div>
            </div>




        </div>
    )
}

export default Categories;