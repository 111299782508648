import React from 'react';
import { Link } from 'react-router-dom';
import { OutlineButton } from '../components/button/Button';

import HeroSlide from '../components/hero-slide/HeroSlide';
import MovieList from '../components/movie-list/MovieList';
import { category, movieType, tvType } from '../api/tmdbApi';





function Home() {
    return (
        <div className="home">
            {/* La partie Caroussel page Principal */}
            <HeroSlide />

            {/* La deuxieme section caroussel des films POPULAIRE */}
            <div className="container">
                <div className="section mb-3">
                    <div className="section_header mb-2">
                        <h2>Films Populaire</h2>
                        <Link to="/movie">
                            <OutlineButton className="small">
                                Voir Plus
                            </OutlineButton>
                        </Link>
                    </div>

                    <MovieList category={category.movie} type={movieType.popular} />
                </div>


                {/* La Troisiéme section caroussel des films TOP RATED */}
                <div className="section mb-3">
                    <div className="section_header mb-2">
                        <h2>Films Mieux Noté</h2>
                        <Link to="/movie">
                            <OutlineButton className="small">
                                Voir Plus
                            </OutlineButton>
                        </Link>
                    </div>

                    <MovieList category={category.movie} type={movieType.top_rated} />
                </div>



                {/* La Quatriéme section caroussel des films Upcomping */}
                <div className="section mb-3">
                    <div className="section_header mb-2">
                        <h2>Films À venir</h2>
                        <Link to="/movie">
                            <OutlineButton className="small">
                                Voir Plus
                            </OutlineButton>
                        </Link>
                    </div>

                    <MovieList category={category.movie} type={movieType.upcoming} />
                </div>



                {/* La Cinquiéme section caroussel des Serie Popular */}

                {/* La Cinquiéme section caroussel des Serie Popular  */}

                <div className="section mb-3">
                    <div className="section_header mb-2">
                        <h2>Serie Popular</h2>
                        <Link to="/tv">
                            <OutlineButton className="small">
                                Voir Plus
                            </OutlineButton>
                        </Link>
                    </div>

                    <MovieList category={category.tv} type={tvType.popular} />
                </div>



                {/* La Sixiéme section caroussel des Serie Mieux Noté */}

                {/*   */}
                <div className="section mb-3">
                    <div className="section_header mb-2">
                        <h2>Serie Mieux Noté</h2>
                        <Link to="/tv">
                            <OutlineButton className="small">
                                Voir Plus
                            </OutlineButton>
                        </Link>
                    </div>

                    <MovieList category={category.tv} type={tvType.top_rated} />
                </div>

            </div>


        </div>
    );
}

export default Home;
