import React, { useState, useEffect } from "react";
import axios from "axios";
import PageHeader from "../components/page-header/PageHeader";
import './actordetails.scss';


const ActorDetails = (props) => {

    const id = props.location.state.id;
    const [detail, setDetail] = useState({});
    const URL = `https://api.themoviedb.org/3/person/${id}?api_key=ab81b730b71d8188cdd162707e3ce5e1&language=en-US`

    useEffect(() => {
        axios.get(URL).then((res) => {
            setDetail(res.data);
        });
    }, [id]);

    return (


        <React.Fragment>
            <PageHeader>

                Acteur

            </PageHeader>

            <div className="details-container">

                <div className="movie_card" id="bright">
                    <div className="info_section">
                        <div className="movie_header">
                            <div className="imgactor">
                                <img
                                    className="locandina"
                                    src={
                                        detail.profile_path ?
                                            `https://image.tmdb.org/t/p/w300${detail.profile_path}` :
                                            "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                                    }
                                    alt="poster"
                                />
                            </div>
                            <div className="data">
                                <h1>{detail.name}</h1>
                                <h4>{detail.birthday}</h4>
                                <span className="minutes">Place of birth : {detail.place_of_birth}</span>
                                <div className="movie_desc">
                                    <br></br>
                                    <p className="textActor">{detail.biography}</p>
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};

export default ActorDetails;