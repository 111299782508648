import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Home from '../pages/Home';
import Catalog from '../pages/Catalog';
import Detail from '../pages/Detail';
import Categories from '../pages/Categories';
import About from '../pages/About';
import ActorDetails from '../pages/ActorDetails';

const Routes = () => {
    return (
        <Switch>
            <Route
                path='/acteur'
                component={ActorDetails}
            />

            <Route
                path='/search'
                component={Categories}
            />

            <Route
                path='/search'
                component={Categories}
            />

            <Route
                path='/apropos'
                component={About}
            />
            <Route
                path='/:category/search/:keyword'
                component={Catalog}
            />
            <Route
                path='/:category/:id'
                component={Detail}
            />

            <Route
                path='/:category'
                component={Catalog}
            />
            <Route
                path='/'
                exact
                component={Home}
            />
        </Switch>
    );
}

export default Routes;
